import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Import Content
import { unitConversionContentLaengen as laengen } from '../../assets/content';

/**
 * Component Laengen Umrechnen
 */
function Laengen() {
  const { t } = useTranslation([]);

  // state für input select
  const [unit1, setUnit1] = useState('m');
  // state für output select
  const [unit2, setUnit2] = useState('mm');
  // state für das Eigabefeld
  const [input, setInput] = useState(1);
  // state für das Ausgabefeld
  const [output, setOutput] = useState(0);

  useEffect(() => {
    // ist das Eingabefeld nicht leer und die Eingabe ist eine Nummer wird die Ausgabe berechnet
    if (isNaN(input) === false && input !== '') {
      setOutput(
        parseFloat(
          ((input * laengen[unit1].unit) / laengen[unit2].unit).toPrecision(15)
        )
      );
    }
  }, [unit1, unit2, input]); // aktualisiert sich wenn sich diese states ändern.

  /**
   * Funktion um das target ins clipboard zu kopieren
   * @param {event} e  Feld das angeklickt wurde
   */
  const _copyCodeToClipboard = (e) => {
    alert('Copied ' + e.value + ' to clipboard');
    e.select();
    document.execCommand('copy');
  };

  /**
   * Funktion um target der select Felder auszutauschen
   */
  const _changeUnits = () => {
    let copy1 = unit1;
    let copy2 = unit2;
    setUnit1(copy2);
    setUnit2(copy1);
  };

  return (
    <Container className='laengen conversionElement'>
      <Row noGutters={true}>
        <Col>
          <h1>{t('UNITCONVERSION.LENGTH.TITLE')}</h1>
        </Col>
      </Row>
      {/* Rauheit umrechner */}
      <Row noGutters={true}>
        <Col>
          <Form>
            {/* Input Felder */}
            <fieldset>
              <legend>{t('UNITCONVERSION.INPUT')}</legend>
              <Form.Row>
                <Col sm={8} md={6} lg={6} xl={4}>
                  {/* Feld */}
                  <Form.Group controlId='form1field1'>
                    <Form.Control
                      type='number'
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col sm={4} md={3} lg={2} xl={1}>
                  {/* Feld */}
                  <Form.Group controlId='form1field2'>
                    <Form.Control
                      as='select'
                      value={unit1}
                      onChange={(e) => setUnit1(e.target.value)}
                    >
                      {Object.keys(laengen).map((keyName, index) => (
                        <option
                          key={index.toString()}
                          value={laengen[keyName].value}
                        >
                          {laengen[keyName].name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </fieldset>
            {/* Output Felder */}
            <fieldset>
              <legend>{t('UNITCONVERSION.OUTPUT')}</legend>
              <Form.Row>
                <Col sm={8} md={6} lg={6} xl={4}>
                  {/* Feld */}
                  <Form.Group controlId='form1field3'>
                    <Form.Control
                      type='number'
                      value={output}
                      readOnly
                      onClick={(e) => _copyCodeToClipboard(e.target)}
                      className='noselect output'
                    />
                  </Form.Group>
                </Col>
                <Col sm={4} md={3} lg={2} xl={1}>
                  {/* Feld */}
                  <Form.Group controlId='form1field4'>
                    <Form.Control
                      as='select'
                      value={unit2}
                      onChange={(e) => setUnit2(e.target.value)}
                    >
                      {Object.keys(laengen).map((keyName, index) => (
                        <option
                          key={index.toString()}
                          value={laengen[keyName].value}
                        >
                          {laengen[keyName].name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </fieldset>
          </Form>
        </Col>
      </Row>
      {/* Button zum select Unit tauschen */}
      <Row noGutters={true}>
        <Col xs={6} sm={4} md={3} lg={3} xl={2}>
          <button className='changeButton' onClick={_changeUnits}>
            <h4>{t('UNITCONVERSION.LENGTH.BUTTON')}</h4>
          </button>
        </Col>
      </Row>
    </Container>
  );
}

export default Laengen;
