import { Container, Row, Col } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

/**
 * Components "Disclaimer"
 */
function Disclaimer() {
  const { t } = useTranslation([]);

  return (
    <Container>
      <Row noGutters>
        <Col>
          <div className='text'>
            <p>
              <Trans i18nKey='DISCLAIMER.SENTENCE'>
                Sentence 1
                <a
                  href={t('DISCLAIMER.LINK')}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  Sentence 2
                </a>
                Sentence 3
              </Trans>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Disclaimer;
