import React, { useState } from 'react';
import { Row, Col, Accordion, Card, Container } from 'react-bootstrap';
import i18next from 'i18next';
import parse from 'html-react-parser';

// import Content
import { filterContent_en, filterContent_de } from '../../assets/content';

// import Images
import infoImg from '../../assets/images/icons/info.svg';

/**
 * Component "Filter-Accordion"
 * @param {Array} props.localMethods eine Array mit dem Status der Filter-Buttons
 * @param {Array} props.localFilterItems eine Array mit dem Status der Filter-Checkboxen
 * @param {Function} props._updateMethods Ändert den State der Methode
 * @param {Function} props._updateFilterItems Ändert den State des Filteritems
 * @param {Function} props._openFilterInfo Öffnet das Info Overlay des Filters
 */
function FilterAccordion(props) {
  const currentLang = i18next.language;
  const filterContent =
    currentLang === 'en' ? filterContent_en : filterContent_de;

  //Gibt an welches Accordion aktiv ist
  const [isActive, setIsActive] = useState(false);

  //Gibt an bei welchem Filter-Infoicon der Hover aktiv ist
  const [hover, setHover] = useState(false);

  /**
   * setzt den state auf false oder index
   * @param {number} index Index welcher angeklickt wurde
   */
  const _handleActive = (index) => {
    //Ist der State bereits der gleiche wie angeklickt, wird der state auf null gesetzt.
    if (isActive === index) {
      setIsActive(false);
    } else {
      setIsActive(index);
    }
  };

  return (
    <Accordion activeKey={isActive.toString()}>
      <Container>
        <Row noGutters={true}>
          <Col>
            {/* Iteriert durch alle Section des Filter */}
            {filterContent.filterSections.map((section, index) => (
              <Card key={index.toString()}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={index.toString()}
                  onClick={() => _handleActive(index)}
                  className={isActive === index ? 'open' : 'closed'}
                >
                  <h1>{section}</h1>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={index.toString()}>
                  <Card.Body>
                    {/* Wenn es die erste Iteration ist wird dies ausgegeben */}
                    {index === 0 ? (
                      <Row className='methods'>
                        {filterContent.methods.map((method, i) => (
                          <Col key={i.toString()} xs={6} xl={3}>
                            <button
                              className={
                                'method method-' +
                                (i + 1) +
                                (props.localMethods[i] === 1
                                  ? ' active'
                                  : ' deactivated')
                              }
                              onClick={() => props._updateMethods(i)}
                            >
                              <h3>{parse(method)}</h3>
                            </button>
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      /* Wenn es nicht die erste Iteration ist wird dies ausgegeben */
                      <Row noGutters='true' className='filters'>
                        <Col xs={12}>
                          <ul>
                            {filterContent.filterItems.map((filterItem, i) =>
                              /* Gibt alle Filteritems dieser Section aus */
                              filterItem.fs === index ? (
                                <li key={i.toString()}>
                                  <input
                                    id={'checkbox-' + i}
                                    className='checkbox'
                                    type='checkbox'
                                    onChange={() => props._updateFilterItems(i)}
                                    checked={
                                      props.localFilterItems[i] === 1
                                        ? true
                                        : false
                                    }
                                  />
                                  <label htmlFor={'checkbox-' + i}>
                                    <h5 className={hover === i ? 'hover' : ''}>
                                      {filterItem.fn}
                                    </h5>
                                  </label>
                                  {/* Wenn das Filteritem ein Infoxext hat wird ein icon eingefügt */}
                                  {filterItem.fi !== '' ? (
                                    <span
                                      className='toggleFilterInfo'
                                      onMouseOver={() => setHover(i)}
                                      onMouseLeave={() => setHover(false)}
                                      onClick={() =>
                                        props._openFilterInfo(
                                          filterItem.fn,
                                          filterItem.fi
                                        )
                                      }
                                    >
                                      <img
                                        className='infoImg'
                                        src={infoImg}
                                        alt='(i)'
                                      />
                                    </span>
                                  ) : null}
                                </li>
                              ) : null
                            )}
                          </ul>
                        </Col>
                      </Row>
                    )}
                  </Card.Body>
                </Accordion.Collapse>

                {/* Gibt alle aktiven Checkboxen der Sections aus, wenn das Akkordeon geschlossen wird */}
                {isActive !== index ? (
                  <ul className='activFilters'>
                    {/* Fragt ab um welche Section es sich handelt */}
                    {index === 0
                      ? filterContent.methods.map((method, i) =>
                          props.localMethods[i] === 1 ? (
                            <li key={i.toString()}>
                              <h5>{parse(method)}</h5>
                            </li>
                          ) : null
                        )
                      : filterContent.filterItems.map((filterItem, i) =>
                          filterItem.fs === index ? (
                            props.localFilterItems[i] === 1 ? (
                              <li key={i.toString()}>
                                <h5>{filterItem.fn}</h5>
                              </li>
                            ) : null
                          ) : null
                        )}
                  </ul>
                ) : null}
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    </Accordion>
  );
}

export default FilterAccordion;
