import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

// import Content
import { productsContent_en, productsContent_de } from '../../assets/content';

// import Components
import Product from './Product';

/**
 * Component "AllProducts"
 */
function AllProducts() {
  const currentLang = i18next.language;
  const { t } = useTranslation();

  const productsContent =
    currentLang === 'en' ? productsContent_en : productsContent_de;

  // Greift auf die States zu
  const states = useSelector((state) => state);
  // State Array mit den gefilterten Produkten
  const [filteredProducts, setFilteredProducts] = useState(productsContent);

  useEffect(() => {
    /**
     * Soll Produkt ausgegeben werden ja nein
     * @param {Objekt} product Das abgefragte Produkt
     */
    const _filter = (product) => {
      let output = true; // setzt Variable Output auf true

      // Iteriert die Array des globalen states Filter (nur Filteritems)
      for (
        let index = 0;
        index < states.globalFilter.filterItems.length;
        index++
      ) {
        // Ist das iterierte Item der Array auf 1
        if (states.globalFilter.filterItems[index] === 1) {
          // Sind die Filtereinstellungen des Produkts auch auf 1
          if (product.f['f' + index] === 1) {
            continue; // iteriert weiter
          } else {
            output = false; // setzt die Variable output auf false
            break; // stop iteration
          }
        }
      }

      return output; // Gibt True oder False der Variable zurück
    };

    /**
     * Filtert die Produkte und setzt sie als State
     */
    const filterProducts = () => {
      // setzt den state
      setFilteredProducts(
        // alle Produkte werden gefiltert
        productsContent
          .filter(
            // Filtert mit der Methode
            (filter) =>
              states.globalFilter.methods[filter.m] === 1 || filter.m === null
          )
          .filter((filter) => _filter(filter)) // Filtert mit dem FilterItem
      );
    };
    filterProducts(); // Führt die Funktion aus
  }, [states.globalFilter, productsContent]); // Wird erneut ausgeführt wenn sich der State GlobalFilter ändert

  // Gibt Produkte aus wenn gefiltert wurde
  return states.globalFilter.hasFiltered === true ? (
    <Container id='products'>
      <Row noGutters={true}>
        <Col className='title'>
          {/* Passt den Titel nach Anzahl der gefilterten Produkte an */}
          {states.globalFilter.hasFiltered ===
          false ? null : filteredProducts.length === 1 ? (
            <h1>
              {filteredProducts.length} {t('CONFIGURATOR.PRODUCT_FOUND')}
            </h1>
          ) : filteredProducts.length === 0 ? (
            <h1>{t('CONFIGURATOR.NO_PRODUCTS_FOUND')}</h1>
          ) : (
            <h1>
              {filteredProducts.length} {t('CONFIGURATOR.PRODUCTS_FOUND')}
            </h1>
          )}
        </Col>
      </Row>
      <Row noGutters={true}>
        <Col>
          <ul>
            {/* Gibt alle gefilterten Produkte aus */}
            {filteredProducts.map((product, index) => (
              <Product key={index.toString()} product={product} />
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  ) : null;
}

export default AllProducts;
