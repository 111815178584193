import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//Import Components
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';

// Import pages
import CoatingGuide from './pages/CoatingGuide';
import HertzianPressure from './pages/HertzianPressure';
import UnitConversion from './pages/UnitConversion';
import DemoldingPlastic from './pages/DemoldingPlastic';
import Contacts from './pages/Contacts';
import Error from './pages/Error';
import NoMatch from './pages/NoMatch';

function App() {
  return (
    <Router>
      {/* Wenn die Seite gewechselt wird, scrollt dieser Component die Seite nach oben */}
      <ScrollToTop />
      <Header />
      <div id='main'>
        <Switch>
          {/* Pages */}
          <Route exact path='/' component={CoatingGuide} />
          <Route exact path='/hertzian-pressure' component={HertzianPressure} />
          <Route exact path='/unit-conversion' component={UnitConversion} />
          <Route exact path='/demolding-plastic' component={DemoldingPlastic} />
          <Route exact path='/contacts' component={Contacts} />

          {/* Error Weiterleitung */}
          <Route exact path='/error' component={Error} />

          {/* 404 */}
          <Route path='*' component={NoMatch} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
