import React from 'react';
import { Redirect } from 'react-router-dom';

/**
 * Seite "No Match", leitet auf die Startseite weiter
 */
function NoMatch() {
  return <Redirect to='/' />;
}

export default NoMatch;
