let initialState = {
  hasFiltered: false,
  methods: [1, 1, 1, 1],
  filterItems: [
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0
  ]
};

// Fragt ab ob der initialstate von sessionstorage oder vom standard gesetzt wird
if (sessionStorage.getItem('globalFilter') !== null) {
  try {
    initialState = JSON.parse(sessionStorage.getItem('globalFilter'));
  } catch (err) {
    console.error(err);
  }
}

/**
 * Gobaler Filter mit welchem gefilter wird
 */
function globalFilter(state = initialState, action) {
  if (action.type === 'SETGLOBALFILTER') {
    try {
      sessionStorage.setItem(
        'globalFilter',
        JSON.stringify(action.globalFilter)
      );
    } catch (err) {
      console.error(err);
    }

    return action.globalFilter;
  } else {
    return state;
  }
}

export default globalFilter;
