import React from 'react';
import { Redirect } from 'react-router-dom';

/**
 * Seite "Error" wird momentan nicht verwendet, aber würde auf die Startseite weiterleiten
 */
function Error() {
  return <Redirect to='/' />;
}

export default Error;
