import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/**
 * Component "Navi"
 * @param {Function} props.closeNav Funktion mit welcher die Navigation geschlossen wird.
 */
function Navi(props) {
  const { t } = useTranslation([]);
  return (
    // Navi wird ausgegeben
    <Nav className='mr-auto'>
      {/* Navi-Item mit Link zu einer Seite der Webapp */}
      <Nav.Item onClick={props.closeNav}>
        <NavLink
          className='nav-link'
          activeClassName='active'
          role='button'
          to='/'
          exact
        >
          <h2>{t('NAVI.ITEM-1')}</h2>
        </NavLink>
      </Nav.Item>
      {/* Navi-Item mit Link zu einer Seite der Webapp */}
      <Nav.Item onClick={props.closeNav}>
        <NavLink
          className='nav-link'
          activeClassName='active'
          role='button'
          to='/hertzian-pressure'
          exact
        >
          <h2>{t('NAVI.ITEM-2')}</h2>
        </NavLink>
      </Nav.Item>
      {/* Navi-Item mit Link zu einer Seite der Webapp */}
      <Nav.Item onClick={props.closeNav}>
        <NavLink
          className='nav-link'
          activeClassName='active'
          role='button'
          to='/unit-conversion'
          exact
        >
          <h2>{t('NAVI.ITEM-3')}</h2>
        </NavLink>
      </Nav.Item>
      {/* Navi-Item mit Link zu einer Seite der Webapp */}
      <Nav.Item onClick={props.closeNav}>
        <NavLink
          className='nav-link'
          activeClassName='active'
          role='button'
          to='/demolding-plastic'
          exact
        >
          <h2>{t('NAVI.ITEM-4')}</h2>
        </NavLink>
      </Nav.Item>
      {/* Navi-Item mit Link zu einer Seite der Webapp */}
      { /* <Nav.Item onClick={props.closeNav}>
        <NavLink
          className='nav-link'
          activeClassName='active'
          role='button'
          to='/contacts'
          exact
        >
          <h2>{t('NAVI.ITEM-5')}</h2>
        </NavLink>
      </Nav.Item> */
      }
      {/* Navi-Item mit Link zu einer Externen Seite */}
      <Nav.Item onClick={props.closeNav}>
        <a
          className='nav-link'
          role='button'
          href={t('NAVI.ITEM-6_LINK')}
          target='_blank'
          rel='noopener noreferrer'
        >
          <h2>{t('NAVI.ITEM-6')}</h2>
        </a>
      </Nav.Item>
    </Nav>
  );
}

export default Navi;
