import React, { useState } from 'react';
import { Navbar, Container, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

// Import Components
import Navi from './Navi';

// Import SVG
import logoBlue from '../assets/images/logo/logo_demartin_blue.svg';
import logoWhite from '../assets/images/logo/logo_demartin_white.svg';
//import filter from '../assets/images/icons/filter_blue.svg';

// Import Actions
import { openFilterOverlay } from '../redux/actions/filterOverlayAction';

/**
 * Component "Header"
 */
function Header() {
  const currentLang = i18next.language;
  const { t } = useTranslation([]);

  // State ob das Hamburger menu offen oder geschlossen ist.
  const [navExpanded, setNavExpanded] = useState(false);

  // Eine action kann übergeben werden
  const dispatch = useDispatch();

  // Location der Navigation
  // const location = useLocation();

  const _changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  /**
   * Setzt den State navExpanded auf false
   */
  const _closeNav = () => {
    setNavExpanded(false);
  };

  /**
   * Setzt den State navExpanded auf expanded
   * @param {boolean} expanded Schliesst oder Öffnet das Menu
   */
  const _expandNav = (expanded) => {
    setNavExpanded(expanded);
  };

  /**
   * Ruft eine Action auf umd den gobalen State zu ändern.
   */
  const _openFilterOverlay = () => {
    dispatch(openFilterOverlay());
  };

  return (
    // Header wird ausgegeben
    <header id='header'>
      <Modal animation={false} autoFocus={false} show={navExpanded}></Modal>
      <Navbar
        className={navExpanded === true ? 'closed' : 'open'}
        onToggle={_expandNav}
        id='navigation'
        expand='xs'
        expanded={navExpanded}
      >
        <Container>
          <div className='overlayToggle'>
            {/*
            {location.pathname === '/' ? (
              <div onClick={_openFilterOverlay}>
                <img src={filter} alt='F' className='filter' />
              </div>
            ) : null}
             */}
          </div>

          {/* Klick auf Logo führt zur Startseite */}
          <a href={t('LOGO_LINK')}>
            <img
              src={logoBlue}
              width='161'
              height='19.84'
              className='d-inline-block align-top'
              alt='Logo'
            />
          </a>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
        </Container>

        {/* Navigation Overlay */}
        <div className='menu'>
          <header>
            <Container>
              {/* Klick auf Logo führt zur Startseite */}
              <a href={t('LOGO_LINK')}>
                <img
                  src={logoWhite}
                  width='161'
                  height='19.84'
                  className='d-inline-block align-top'
                  alt='Logo'
                />
              </a>
              <Navbar.Toggle aria-controls='basic-navbar-nav' />
            </Container>
          </header>
          <Container>
            <Navbar.Collapse id='basic-navbar-nav'>
              <div className='lang'>
                <span
                  onClick={() => _changeLanguage('de')}
                  className={`langItem${currentLang === 'de' ? ' active' : ''}${
                    currentLang === 'en' ? ' inverse' : ''
                  }`}
                >
                  <span>DE</span>
                </span>

                <span
                  onClick={() => _changeLanguage('en')}
                  className={`langItem${currentLang === 'en' ? ' active' : ''}`}
                >
                  <span>EN</span>
                </span>
              </div>
              {/* Navigation wird ausgegeben */}
              <Navi closeNav={_closeNav} />

              {process.env.NODE_ENV !== 'production' ? (
                <button onClick={() => _changeLanguage('cimode')}>Debug</button>
              ) : null}
            </Navbar.Collapse>
          </Container>
        </div>
      </Navbar>
    </header>
  );
}

export default Header;
