/**
 * Öffnet das Filter Overlay
 * @param {Der neue Headertitle} newheadertitle
 */
export function openFilterOverlay() {
  return {
    type: 'OPENFILTER'
  };
}

/**
 * Schliesst das Filter Overlay
 */
export function closeFilterOverlay() {
  return {
    type: 'CLOSEFILTER'
  };
}

/**
 * Setzt den Globalenfilter
 */
export function setGlobalFilter(localMethods, localfilterItems) {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  let bool = false;
  if (
    localMethods.reduce(reducer) === 4 &&
    localfilterItems.reduce(reducer) === 0
  ) {
    bool = false;
  } else {
    bool = true;
  }

  let globalFilter = { hasFiltered: bool, methods: [], filterItems: [] };
  globalFilter.methods = localMethods;
  globalFilter.filterItems = localfilterItems;

  return {
    type: 'SETGLOBALFILTER',
    globalFilter: globalFilter
  };
}
