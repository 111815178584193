import React, { useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import images
import close from '../../assets/images/icons/cross_blue.svg';
import back from '../../assets/images/icons/arrow_back.svg';

// import Components
import FilterAccordion from './FilterAccordion';
import FilterInfo from './FilterInfo';

// import Actions
import {
  closeFilterOverlay,
  setGlobalFilter,
} from '../../redux/actions/filterOverlayAction';

/**
 * Component "FilterOverlay"
 */
function FilterOverlay() {
  const { t } = useTranslation();

  // Greift auf die States zu
  const states = useSelector((state) => state);

  // Eine action kann übergeben werden
  const dispatch = useDispatch();

  // Standard InitialState der FilterItem und Methoden
  const [localInitialState] = useState({
    methods: [1, 1, 1, 1],
    filterItems: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
  });

  /**
   * Setzt den session storage Filter oder den lokalen intitialState
   */
  const getInitialState = () => {
    // Variable initialState wird erstellt und auf den lokalen initialState gesetzt
    let initialState = localInitialState;

    // Fragt ab ob sich ein Item im SessionStorage befindet
    if (sessionStorage.getItem('globalFilter') !== null) {
      // Probiert die Variable InitialState in das SessionStorage Item zu ändern
      try {
        initialState = JSON.parse(sessionStorage.getItem('globalFilter'));
      } catch (err) {
        console.error(err);
      }
    }
    // Return InitialState
    return initialState;
  };

  // Aktive Local Filter States Methoden
  const [localMethods, setLocalMethods] = useState(
    () => getInitialState().methods
  );

  // Aktive Local Filter States FilterItems
  const [localFilterItems, setLocalFilterItems] = useState(
    () => getInitialState().filterItems
  );

  // Boolean State ob das Filterinfo Overlay Aktiv ist
  const [filterInfoIsActive, setFilterInfoIsActive] = useState(false);

  // Objekt State mit dem aktiven Infos über den angeklickten Filter
  const [filterInfoContent, setFilterInfoContent] = useState({
    title: null,
    text: null,
  });

  /**
   * Updatet den lokalen State der Methoden
   * @param {number} index Index der anzupassenden Methode
   */
  const _updateMethods = (index) => {
    // Kopiert die alte Array
    let newArr = [...localMethods];
    // Ändert den Status des array Index
    if (localMethods[index] === 0) {
      newArr[index] = 1;
    } else {
      newArr[index] = 0;
    }
    // Setzt die angepasste Array wieder als state
    setLocalMethods(newArr);
  };

  /**
   * Updatet den lokalen State der FilterItems
   * @param {number} index Index des anzupassendem Item
   */
  const _updateFilterItems = (index) => {
    // Kopiert die alte Array
    let newArr = [...localFilterItems];
    // Ändert den Status des array Index
    if (localFilterItems[index] === 0) {
      newArr[index] = 1;
    } else {
      newArr[index] = 0;
    }
    // Setzt die angepasste Array wieder als state
    setLocalFilterItems(newArr);
  };

  /**
   * Schliesst das Filter Overlay und ändert den globalen Filter State zum lokalen Filter State
   */
  const _setGlobalFilterCloseOverlay = () => {
    // Ruft Action zum Schliessen des Filter Overlays
    dispatch(closeFilterOverlay());
    // Ruft Action um den globalen Filter State in den localen zu ändern
    dispatch(setGlobalFilter(localMethods, localFilterItems));
    // Schliesst das Info Overlay
    setFilterInfoIsActive(false);
  };

  /**
   * Setzt die Filter zurück auf den standard initialState
   */
  const _resetLocalFilter = () => {
    // Setzt den lokalen State der Filteritems zurück
    setLocalFilterItems(localInitialState.filterItems);
    // Setzt den lokalen State der Methoden zurück
    setLocalMethods(localInitialState.methods);
    // Schliesst das Info Overlay
    setFilterInfoIsActive(false);
    // Ruft Action um den globalen Filter State zurückzusetzten
    dispatch(
      setGlobalFilter(localInitialState.methods, localInitialState.filterItems)
    );
  };

  /**
   * Öffnet das Info Overlay
   * @param {String} title Titel des Filters
   * @param {String} text  Infotext des Filters
   */
  const _openFilterInfo = (title, text) => {
    // setzt den Filter Info state mit dem neuen Objekt des angeklickten Filters
    setFilterInfoContent({ title: title, text: text });
    // setzt den State auf true und öffnet damit das Info Overlay
    setFilterInfoIsActive(true);
  };

  /**
   * Schliesst das Info Overlay
   */
  const _closeFilterInfo = () => {
    // setzt den State auf false und schliesst damit das Info Overlay
    setFilterInfoIsActive(false);
  };

  return (
    <>
      {/* Modal im Hintergrund damit scrollen im Hintergrund deaktiviert wird */}
      <Modal
        animation={false}
        autoFocus={false}
        show={states.filterOverlay}
      ></Modal>
      {/* Gibt das Filter Overlay aus */}
      <div
        className={states.filterOverlay === true ? 'open' : null}
        id='filterOverlay'
      >
        {/* Header */}
        <header>
          <Container>
            {filterInfoIsActive === true ? (
              <span onClick={_closeFilterInfo}>
                <img src={back} alt='X' className='back' />
              </span>
            ) : null}

            <h1>{t('CONFIGURATOR.TITLE')}</h1>
            <span onClick={_setGlobalFilterCloseOverlay}>
              <img src={close} alt='X' className='close' />
            </span>
          </Container>
        </header>
        {/* Der Filter mit den Akkordeons */}
        <div className='filterOverlayMain'>
          <div className='filterAccordion'>
            <FilterAccordion
              _openFilterInfo={_openFilterInfo}
              _updateMethods={_updateMethods}
              _updateFilterItems={_updateFilterItems}
              localMethods={localMethods}
              localFilterItems={localFilterItems}
            />
          </div>
          {filterInfoIsActive === true ? (
            <div className='filterInfo'>
              {/* Filter Info Overlay */}
              <FilterInfo filterInfoContent={filterInfoContent} />
            </div>
          ) : null}
        </div>
        {/* Footer */}
        <footer>
          <Container>
            <Row>
              {/* Reset Button */}
              <Col xs={6}>
                <button className='resetButton' onClick={_resetLocalFilter}>
                  <h4>{t('CONFIGURATOR.BUTTON_RESET')}</h4>
                </button>
              </Col>
              {/* Result Button */}
              <Col xs={6}>
                <button
                  className='resultButton'
                  onClick={_setGlobalFilterCloseOverlay}
                >
                  <h4>{t('CONFIGURATOR.BUTTON_RESULT')}</h4>
                </button>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </>
  );
}

export default FilterOverlay;
