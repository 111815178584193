import CONTACTS_DE from './de/contacts_de.json';
import CONTACTS_EN from './en/contacts_en.json';
import DEMOLDINGPLASTICCONTENT_DE from './de/demoldingPlasticContent_de.json';
import DEMOLDINGPLASTICCONTENT_EN from './en/demoldingPlasticContent_en.json';
import FILTERCONTENT_DE from './de/filterContent_de.json';
import FILTERCONTENT_EN from './en/filterContent_en.json';
import PRODUCTSCONTENT_DE from './de/productsContent_de.json';
import PRODUCTSCONTENT_EN from './en/productsContent_en.json';

import UNITCONVERSIONCONTENTHAERTE from './unitConversionContentHaerte.json';
import UNITCONVERSIONCONTENTLAENGEN from './unitConversionContentLaengen.json';
import UNITCONVERSIONCONTENTRAUHEIT from './unitConversionContentRauheit.json';

export let contacts_en = CONTACTS_EN;
export let contacts_de = CONTACTS_DE;

export let demoldingPlasticContent_en = DEMOLDINGPLASTICCONTENT_EN;
export let demoldingPlasticContent_de = DEMOLDINGPLASTICCONTENT_DE;

export let filterContent_en = FILTERCONTENT_EN;
export let filterContent_de = FILTERCONTENT_DE;

export let productsContent_en = PRODUCTSCONTENT_EN;
export let productsContent_de = PRODUCTSCONTENT_DE;

export let unitConversionContentHaerte = UNITCONVERSIONCONTENTHAERTE;
export let unitConversionContentLaengen = UNITCONVERSIONCONTENTLAENGEN;
export let unitConversionContentRauheit = UNITCONVERSIONCONTENTRAUHEIT;
