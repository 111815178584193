import { combineReducers } from 'redux';

// import alle Reducer
import filterOverlay from './reducers/filterOverlay';
import globalFilter from './reducers/globalFilter';

/**
 * Fügt alle Reducer zusammen.
 */
export default combineReducers({
  filterOverlay: filterOverlay,
  globalFilter: globalFilter
});
