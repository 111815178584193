import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enLang from './en.json';
import deLang from './de.json';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'de'];

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: enLang,
  },
  de: {
    translation: deLang,
  },
};
i18next
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: process.env.NODE_ENV === 'production' ? false : true,
    fallbackLng: fallbackLng, // fallback language is english.
    detection: {
      checkWhitelist: true, // options for language detection
    },
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

i18next.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});
