import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import Actions
import { openFilterOverlay } from '../redux/actions/filterOverlayAction';

// Import Components
import FilterOverlay from '../components/filter-overlay/FilterOverlay';
import AllProducts from '../components/products/AllProducts';

// Import Image
import valentino from '../assets/images/valentino.jpg';

/**
 * Seite "Configurator"
 */
function CoatingGuide() {
  const { t } = useTranslation();
  // Eine action kann übergeben werden
  const dispatch = useDispatch();
  // Greift auf die States zu
  const states = useSelector((state) => state);

  /**
   * Call action um Filter Overlay zu öffnen
   */
  const _openFilterOverlay = () => {
    dispatch(openFilterOverlay());
  };

  return (
    <section id='coatingGuide'>
      <FilterOverlay />
      {states.globalFilter.hasFiltered === false ? (
        <>
          {/* Element 1 */}
          <div id='gotIt' className='homeElement'>
            <Container>
              <Row noGutters={true}>
                <Col>
                  <h1>{t('COATINGGUIDE.H1-1')}</h1>
                  {/*<h6>{t('COATINGGUIDE.H6-1')}</h6>*/}
                  <p>{t('COATINGGUIDE.P-1')}</p>
                  <ol>
                    <li>{t('COATINGGUIDE.OL_LI-1')}</li>
                    <li>{t('COATINGGUIDE.OL_LI-2')}</li>
                    <li>{t('COATINGGUIDE.OL_LI-3')}</li>
                  </ol>
                </Col>
              </Row>
              {/* Configurator Button */}
              {/*
              <Row className='buttons' noGutters={true}>
                <Col
                  sm={12}
                  md={{ span: 6, offset: 6 }}
                  lg={{ span: 6, offset: 6 }}
                  xl={{ span: 6, offset: 6 }}
                >
                  <button
                    className='filtersButton'
                    onClick={_openFilterOverlay}
                  >
                    <h4>{t('COATINGGUIDE.BUTTON_CONFIGURATOR')}</h4>
                  </button>
                </Col>
             </Row> */}
            </Container>
          </div>
          {/* Element 2 */}
          <div id='valentino' className='homeElement'>
            <Container>
              <Row noGutters={true}>
                <Col sm={12} lg={6}>
                  <div className='text'>
                    <h1>{t('COATINGGUIDE.H1-2')}</h1>
                    <h6>{t('COATINGGUIDE.H6-2')}</h6>
                  </div>
                </Col>
                <Col sm={6}>
                  <img
                    className='valentinoImg'
                    src={valentino}
                    alt='Valentino De Martin'
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </>
      ) : null}

      <AllProducts />
    </section>
  );
}

export default CoatingGuide;
