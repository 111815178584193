import React from 'react';
import { Link } from 'react-router-dom';

const productsUrl = 'https://demartin.com/hightech-surfaces/detail-product';

/**
 * Component Product
 * @param {Object} props.product ein Objekt mit dem auszugebenden Produkt
 */
function Product(props) {
  return (
    // Gibt ein li mit className für die Farbe aus
    <li className={'product color-' + props.product.c}>
      {/* Ist der Link props leer wird ein interner Link zur Contacts Seite hinzugefügt */}
      {props.product.l === '' ? (
        <Link to='/contacts/'>
          <h4>{props.product.n}</h4>
        </Link>
      ) : (
        // Sonnst wird der externe link hinzugefügt
        <a
          href={productsUrl + props.product.l}
          target='_blank'
          rel='noopener noreferrer'
        >
          <h4>{props.product.n}</h4>
        </a>
      )}
    </li>
  );
}

export default Product;
