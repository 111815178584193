import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { contacts_en, contacts_de } from '../assets/content';

/**
 * Seite "Demolding Plastic"
 */
function Contacts() {
  const currentLang = i18next.language;
  const { t } = useTranslation([]);
  const contacts = currentLang === 'en' ? contacts_en : contacts_de;

  return (
    <section id='contacts'>
      <Container>
        <Row noGutters={true}>
          <Col>
            <h1>{t('CONTACTS.TITLE')}</h1>
          </Col>
        </Row>
        <Row noGutters={true}>
          {contacts.map((contact, index) => (
            <Col key={index.toString()} md={6} className='contact'>
              <div>
                <div className='contactInfo'>
                  <h5>{contact.name}</h5>
                  <h6>{contact.job}</h6>
                  {contact.tel ? (
                    <a href={`tel:${contact.tel}`}>
                      <p>T {contact.tel}</p>
                    </a>
                  ) : null}
                  {contact.mobile ? (
                    <a href={`tel:${contact.mobile}`}>
                      <p>M {contact.mobile}</p>
                    </a>
                  ) : null}
                  {contact.email ? (
                    <a href={`mailto:${contact.email}`}>
                      <p>{contact.email}</p>
                    </a>
                  ) : null}
                </div>
                <img
                  alt={contact.name}
                  title={contact.name}
                  src={`${process.env.PUBLIC_URL}/assets/images/team/${contact.img}`}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default Contacts;
