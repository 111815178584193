let initialState = false;

/**
 * Öffnet oder schliesst das FilterOverlay
 */
function filterOverlay(state = initialState, action) {
  if (action.type === 'CLOSEFILTER') {
    return false;
  } else if (action.type === 'OPENFILTER') {
    return true;
  } else {
    return state;
  }
}

export default filterOverlay;
