import React, { useState } from 'react';
import { Container, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Import Components
import Disclaimer from '../components/Disclaimer';

/**
 * Seite "Hertzian Pressure"
 */
function HertzianPressure() {
  const { t } = useTranslation([]);

  // States aller Eingabefelder
  const [result, setResult] = useState(null);
  const [body1, setBody1] = useState('kugel');
  const [body2, setBody2] = useState('kugel');
  const [r1, setR1] = useState(5);
  const [r2, setR2] = useState(5);
  const [fn, setFn] = useState(100);
  const [e1, setE1] = useState(210000);
  const [e2, setE2] = useState(210000);
  const [v1, setV1] = useState(0.3);
  const [v2, setV2] = useState(0.3);
  const [l, setL] = useState(1);
  const [w, setW] = useState(0);

  /**
   * Resultat wird berechnet
   * @param {event} event Formular welches gesubmited wird
   */
  const _rechnen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let resultat;

    // Welche Formel verwendet wird um zu rechnen
    if (body1 === 'kugel' && body2 === 'kugel') {
      console.log("body1 === 'kugel' && body2 === 'kugel'");
      resultat =
        (1 / Math.PI) *
        Math.cbrt(
          ((1.5 * fn) / Math.pow((r1 * r2) / (r1 + r2), 2)) *
            Math.pow(
              1 /
                ((1 / 2) *
                  ((1 - Math.pow(v1, 2)) / e1 + (1 - Math.pow(v2, 2)) / e2)),
              2
            )
        );
    } else if (body1 === 'zylinder' && body2 === 'zylinder') {
      console.log("body1 === 'zylinder' && body2 === 'zylinder'");
      if (w === 0) {
        resultat = Math.sqrt(
          (fn / (2 * Math.PI * ((r1 * r2) / (r1 + r2)) * l)) *
            ((2 * 1) /
              ((1 - Math.pow(v1, 2)) / e1 + (1 - Math.pow(v2, 2)) / e2))
        );
      } else {
        resultat =
          (1 / Math.PI) *
          Math.cbrt(
            ((1.5 * fn) / Math.pow(r1, 2)) *
              Math.pow(
                1 /
                  ((1 / 2) *
                    ((1 - Math.pow(v1, 2)) / e1 + (1 - Math.pow(v2, 2)) / e2)),
                2
              )
          );
      }
    } else if (
      (body1 === 'kugel' && body2 === 'zylinder') ||
      (body2 === 'kugel' && body1 === 'zylinder')
    ) {
      console.log("body1 === 'kugel' && body2 === 'zylinder'");
      resultat =
        (1 / Math.PI) *
        Math.cbrt(
          ((1.5 * fn) / Math.pow(r1, 2)) *
            Math.pow(
              1 /
                ((1 / 2) *
                  ((1 - Math.pow(v1, 2)) / e1 + (1 - Math.pow(v2, 2)) / e2)),
              2
            )
        );
    } else if (body1 === 'kugel' && body2 === 'ebene') {
      console.log("body1 === 'kugel' && body2 === 'ebene'");
      resultat =
        (1 / Math.PI) *
        Math.cbrt(
          ((1.5 * fn) / Math.pow(r1, 2)) *
            Math.pow(
              1 /
                ((1 / 2) *
                  ((1 - Math.pow(v1, 2)) / e1 + (1 - Math.pow(v2, 2)) / e2)),
              2
            )
        );
    } else if (body1 === 'zylinder' && body2 === 'ebene') {
      console.log("body1 === 'zylinder' && body2 === 'ebene'");
      resultat = Math.sqrt(
        (fn / (2 * Math.PI * r1 * l)) *
          ((2 * 1) / ((1 - Math.pow(v1, 2)) / e1 + (1 - Math.pow(v2, 2)) / e2))
      );
    } else {
      resultat = 0;
    }

    setResult(resultat.toFixed(3));
  };

  return (
    <section id='hertzianPressure'>
      <Container>
        <Row noGutters={true}>
          <Col>
            <h1>{t('HERTZIANCONTACTSTRESS.TITLE')}</h1>
          </Col>
        </Row>
        <Row noGutters={true}>
          <Col>
            <Form onSubmit={_rechnen}>
              <Form.Row>
                <Col sm={5}>
                  <fieldset>
                    <legend>{t('HERTZIANCONTACTSTRESS.BODY')} 1</legend>
                    {/* Feld */}
                    <Form.Group controlId='body1id1'>
                      <Form.Control
                        as='select'
                        value={body1}
                        onChange={(e) => setBody1(e.target.value)}
                      >
                        <option value='kugel'>
                          {t('HERTZIANCONTACTSTRESS.SPHERE')}
                        </option>
                        <option value='zylinder'>
                          {t('HERTZIANCONTACTSTRESS.CYLINDER')}
                        </option>
                      </Form.Control>
                    </Form.Group>
                    {/* Feld */}
                    <Form.Group controlId='body1id2'>
                      <Form.Label>
                        {t('HERTZIANCONTACTSTRESS.RADIUS')}
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            r<sub>1</sub>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          required
                          type='number'
                          step='1'
                          min='1'
                          defaultValue={r1}
                          onChange={(e) => setR1(parseFloat(e.target.value))}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>mm</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                    {/* Feld */}
                    <Form.Group controlId='body1id3'>
                      <Form.Label>
                        {t('HERTZIANCONTACTSTRESS.ELASTICMODULUS')}
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            E<sub>1</sub>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          required
                          type='number'
                          defaultValue={e1}
                          onChange={(e) => setE1(parseFloat(e.target.value))}
                          step='1'
                          min='1'
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>MPa</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                    {/* Feld */}
                    <Form.Group controlId='body1id4'>
                      <Form.Label>
                        {t('HERTZIANCONTACTSTRESS.POISSONSRATIO')}
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            ν<sub>1</sub>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          required
                          type='number'
                          defaultValue={v1}
                          step='0.1'
                          min='0.1'
                          max='0.5'
                          onChange={(e) => setV1(parseFloat(e.target.value))}
                        />
                      </InputGroup>
                    </Form.Group>
                  </fieldset>
                </Col>
                <Col sm={{ span: 5, offset: 2 }}>
                  <fieldset>
                    <legend>Body 2</legend>
                    {/* Feld */}
                    <Form.Group controlId='body2id1'>
                      <Form.Control
                        as='select'
                        value={body2}
                        onChange={(e) => setBody2(e.target.value)}
                      >
                        <option value='kugel'>
                          {t('HERTZIANCONTACTSTRESS.SPHERE')}
                        </option>
                        <option value='zylinder'>
                          {t('HERTZIANCONTACTSTRESS.CYLINDER')}
                        </option>
                        <option value='ebene'>
                          {t('HERTZIANCONTACTSTRESS.PLANE')}
                        </option>
                      </Form.Control>
                    </Form.Group>
                    {/* Feld */}
                    {body2 !== 'ebene' ? (
                      <Form.Group controlId='body1id2'>
                        <Form.Label>
                          {t('HERTZIANCONTACTSTRESS.RADIUS')}
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              r<sub>2</sub>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            required
                            type='number'
                            defaultValue={r2}
                            onChange={(e) => setR2(parseFloat(e.target.value))}
                            step='1'
                            min='1'
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>mm</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    ) : null}
                    {/* Feld */}
                    <Form.Group controlId='body1id3'>
                      <Form.Label>
                        {t('HERTZIANCONTACTSTRESS.ELASTICMODULUS')}
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            E<sub>2</sub>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          required
                          type='number'
                          defaultValue={e2}
                          onChange={(e) => setE2(parseFloat(e.target.value))}
                          step='1'
                          min='1'
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>MPa</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                    {/* Feld */}
                    <Form.Group controlId='body2id4'>
                      <Form.Label>
                        {t('HERTZIANCONTACTSTRESS.POISSONSRATIO')}
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            ν<sub>2</sub>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          required
                          type='number'
                          defaultValue={v2}
                          step='0.1'
                          min='0.1'
                          max='0.5'
                          onChange={(e) => setV2(parseFloat(e.target.value))}
                        />
                      </InputGroup>
                    </Form.Group>
                  </fieldset>
                </Col>
              </Form.Row>
              {(body1 === 'zylinder' && body2 === 'zylinder') ||
              (body1 === 'zylinder' && body2 === 'ebene') ? (
                <Form.Row>
                  <Col sm={6}>
                    <fieldset>
                      <legend>{t('HERTZIANCONTACTSTRESS.LENGTH')}</legend>
                      {/* Feld */}

                      <Form.Group controlId='l1id1'>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>L</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            required
                            type='number'
                            defaultValue={l}
                            onChange={(e) => setL(parseFloat(e.target.value))}
                            step='1'
                            min='1'
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>mm</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </fieldset>
                  </Col>
                </Form.Row>
              ) : null}
              <Form.Row>
                <Col sm={6}>
                  <fieldset>
                    <legend>{t('HERTZIANCONTACTSTRESS.FORCE')}</legend>
                    {/* Feld */}
                    <Form.Group controlId='n1id1'>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>Fn</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          required
                          type='number'
                          defaultValue={fn}
                          onChange={(e) => setFn(parseFloat(e.target.value))}
                          step='1'
                          min='1'
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>N</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                  </fieldset>
                </Col>
              </Form.Row>
              {body1 === 'zylinder' && body2 === 'zylinder' ? (
                <Form.Row>
                  <Col sm={6}>
                    <fieldset>
                      <legend>{t('HERTZIANCONTACTSTRESS.ANGLE')}</legend>
                      {/* Feld */}
                      <Form.Group controlId='w1id1'>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>α</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            required
                            type='number'
                            max='90'
                            min='0'
                            step='90'
                            defaultValue={w}
                            onChange={(e) => setW(parseFloat(e.target.value))}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>°</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </fieldset>
                  </Col>
                </Form.Row>
              ) : null}
              <button className='submit' type='submit'>
                <h4>{t('HERTZIANCONTACTSTRESS.BUTTON_CALCULATE')}</h4>
              </button>
            </Form>
          </Col>
        </Row>
      </Container>
      {/* Resultat */}
      {result !== null ? (
        <Container>
          <Row noGutters>
            <Col>
              <div className='result'>
                <h5>
                  {t('HERTZIANCONTACTSTRESS.RESULT_SENTENCE')} {result}
                  <span> MPa</span>
                </h5>
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}

      {/* Disclaimer */}
      <Disclaimer />
    </section>
  );
}

export default HertzianPressure;
