import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// import Content
import { unitConversionContentHaerte as content } from '../../assets/content';

/**
 * Component Haerten Umrechnen
 */
function Haerten() {
  const { t } = useTranslation([]);

  // state für den index das Eigabefeldes
  const [inputHarte, setInputHarte] = useState(0);
  // state für das Ausgabefeld
  const [outputHarte, setOutputHarte] = useState('');
  // state für input select
  const [harte1, setHarte1] = useState('nmm');
  // state für output select
  const [harte2, setHarte2] = useState('hv');

  useEffect(() => {
    // Fragt ab welches output select target aktiv ist
    if (harte2 === 'nmm') {
      if (content.nmm[inputHarte] !== null) {
        setOutputHarte(content.nmm[inputHarte]);
      } else {
        setOutputHarte('');
      }
    } else if (harte2 === 'hv') {
      if (content.hv[inputHarte] !== null) {
        setOutputHarte(content.hv[inputHarte]);
      } else {
        setOutputHarte('');
      }
    } else if (harte2 === 'hrc') {
      if (content.hrc[inputHarte] !== null) {
        setOutputHarte(content.hrc[inputHarte]);
      } else {
        setOutputHarte('');
      }
    } else if (harte2 === 'hrb') {
      if (content.hrb[inputHarte] !== null) {
        setOutputHarte(content.hrb[inputHarte]);
      } else {
        setOutputHarte('');
      }
    } else if (harte2 === 'hb') {
      if (content.hb[inputHarte] !== null) {
        setOutputHarte(content.hb[inputHarte]);
      } else {
        setOutputHarte('');
      }
    }
  }, [inputHarte, harte1, harte2]); // aktualisiert sich wenn sich diese states ändern.

  // Wenn der input select aktualisiert wird, ändert sich der input Feld index auf 0
  useEffect(() => {
    setInputHarte(0);
    return () => {
      setInputHarte(0);
    };
  }, [harte1]);

  /**
   * Funktion um das target ins clipboard zu kopieren
   * @param {event} e  Feld das angeklickt wurde
   */
  const _copyCodeToClipboard = (e) => {
    alert('Copied ' + e.value + ' to clipboard');
    e.select();
    document.execCommand('copy');
  };

  /**
   * Funktion die abfragt welches input select target aktiv
   */
  const Input = () => {
    if (harte1 === 'nmm') {
      return content.nmm.map((option, index) =>
        option !== null ? (
          <option key={'nmm' + index.toString()} value={index}>
            {option}
          </option>
        ) : null
      );
    } else if (harte1 === 'hv') {
      return content.hv.map((option, index) =>
        option !== null ? (
          <option key={'hv' + index.toString()} value={index}>
            {option}
          </option>
        ) : null
      );
    } else if (harte1 === 'hrc') {
      return content.hrc.map((option, index) =>
        option !== null ? (
          <option key={'hrc' + index.toString()} value={index}>
            {option}
          </option>
        ) : null
      );
    } else if (harte1 === 'hrb') {
      return content.hrb.map((option, index) =>
        option !== null ? (
          <option key={'hrb' + index.toString()} value={index}>
            {option}
          </option>
        ) : null
      );
    } else if (harte1 === 'hb') {
      return content.hb.map((option, index) =>
        option !== null ? (
          <option key={'hb' + index.toString()} value={index}>
            {option}
          </option>
        ) : null
      );
    }
    return null;
  };

  return (
    <Container className='haerten conversionElement'>
      <Row noGutters={true}>
        <Col>
          <h1>{t('UNITCONVERSION.HARDNESS.TITLE')}</h1>
        </Col>
      </Row>
      {/* Härte umrechner */}
      <Row noGutters={true}>
        <Col>
          <Form>
            {/* Input Felder */}
            <fieldset>
              <legend>{t('UNITCONVERSION.INPUT')}</legend>
              <Form.Row>
                <Col sm={6} md={4} lg={4} xl={2}>
                  <Form.Group controlId='form2field1'>
                    <Form.Control
                      as='select'
                      value={inputHarte}
                      onChange={(e) => setInputHarte(e.target.value)}
                    >
                      <Input />
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col sm={6} md={5} lg={4} xl={3}>
                  <Form.Group controlId='form2field2'>
                    <Form.Control
                      as='select'
                      value={harte1}
                      onChange={(e) => setHarte1(e.target.value)}
                    >
                      <option value='nmm'>
                        N/mm² ({t('UNITCONVERSION.HARDNESS.OPTION-NMM')})
                      </option>
                      <option value='hv'>
                        HV ({t('UNITCONVERSION.HARDNESS.OPTION-HV')})
                      </option>
                      <option value='hrc'>
                        HRC ({t('UNITCONVERSION.HARDNESS.OPTION-HRC')})
                      </option>
                      <option value='hrb'>
                        HRB ({t('UNITCONVERSION.HARDNESS.OPTION-HRB')})
                      </option>
                      <option value='hb'>
                        HB ({t('UNITCONVERSION.HARDNESS.OPTION-HB')})
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </fieldset>
            {/* Output Felder */}
            <fieldset>
              <legend>{t('UNITCONVERSION.OUTPUT')}</legend>
              <Form.Row>
                <Col sm={6} md={4} lg={4} xl={2}>
                  <Form.Group controlId='form2field3'>
                    <Form.Control
                      type='number'
                      value={outputHarte}
                      readOnly
                      onClick={(e) => _copyCodeToClipboard(e.target)}
                      className='noselect output'
                    />
                  </Form.Group>
                </Col>
                <Col sm={6} md={5} lg={4} xl={3}>
                  <Form.Group controlId='form2field4'>
                    <Form.Control
                      as='select'
                      value={harte2}
                      onChange={(e) => setHarte2(e.target.value)}
                    >
                      <option value='nmm'>
                        N/mm² ({t('UNITCONVERSION.HARDNESS.OPTION-NMM')})
                      </option>
                      <option value='hv'>
                        HV ({t('UNITCONVERSION.HARDNESS.OPTION-HV')})
                      </option>
                      <option value='hrc'>
                        HRC ({t('UNITCONVERSION.HARDNESS.OPTION-HRC')})
                      </option>
                      <option value='hrb'>
                        HRB ({t('UNITCONVERSION.HARDNESS.OPTION-HRB')})
                      </option>
                      <option value='hb'>
                        HB ({t('UNITCONVERSION.HARDNESS.OPTION-HB')})
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </fieldset>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Haerten;
