import React, { useState } from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

// Import Content
import {
  demoldingPlasticContent_en,
  demoldingPlasticContent_de,
} from '../assets/content';

/**
 * Seite "Demolding Plastic"
 */
function DemoldingPlastic() {
  const currentLang = i18next.language;
  const { t } = useTranslation([]);
  // Gibt an welches Accordion aktiv ist
  const [isActive, setIsActive] = useState(0);
  const content =
    currentLang === 'en'
      ? demoldingPlasticContent_en
      : demoldingPlasticContent_de;

  /**
   * setzt den state auf false oder index
   * @param {Index welcher angeklickt wurde} index
   */
  let _handleActive = (index) => {
    // Ist der State bereits der gleiche wie angeklickt, wird der state auf false gesetzt.
    if (isActive === index) {
      setIsActive(false);
    } else {
      setIsActive(index);
    }
  };

  return (
    <section id='demoldingPlastic'>
      <Container>
        <Row noGutters={true}>
          <Col>
            <h1>{t('DEMOLDINGPLASTIC.TITLE')}</h1>
          </Col>
        </Row>
        <Row noGutters={true}>
          <Col>
            {/* Akkordeon */}
            <Accordion activeKey={isActive.toString()}>
              {content.tableContent.map((item, index) => (
                <div className='customTable' key={index}>
                  {/* Akkordeon Header */}
                  <div onClick={() => _handleActive(index)}>
                    <div className='header'>
                      <div
                        className={
                          'th colSpan2 ' +
                          (isActive === index ? 'open' : 'closed')
                        }
                      >
                        <h3>
                          {item.title} <br />
                          <span>{item.short}</span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  {/* Akkordeon Body */}
                  <Accordion.Collapse eventKey={index.toString()}>
                    <>
                      {content.thContent.map((thItem, thIndex) => (
                        <React.Fragment key={thIndex}>
                          <div className='th highlight colSpan2'>
                            {thItem.title}
                          </div>

                          {thItem.child.map((tdItem, tdIndex) => (
                            <div className='tr' key={tdIndex}>
                              <div className='td'>{tdItem}</div>
                              <div className='td'>
                                {item.child[2 * thIndex + tdIndex] === 1
                                  ? 'X'
                                  : null}
                              </div>
                            </div>
                          ))}
                        </React.Fragment>
                      ))}
                    </>
                  </Accordion.Collapse>
                </div>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DemoldingPlastic;
