import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Import Components
import Laengen from '../components/unitConversion/Laengen';
import Haerten from '../components/unitConversion/Haerten';
import Rauheit from '../components/unitConversion/Rauheit';
import Disclaimer from '../components/Disclaimer';

/**
 * Seite "Unit Conversion"
 */
function UnitConversion() {
  const { t } = useTranslation([]);

  return (
    <section id='unitConversion'>
      <Container>
        <Row noGutters={true}>
          <Col>
            <h1>{t('UNITCONVERSION.TITLE')}</h1>
          </Col>
        </Row>
      </Container>
      {/* // Laengen Component */}
      <Laengen />

      {/* // Haerten Component */}
      <Haerten />

      {/* // Rauheit Component */}
      <Rauheit />

      {/* Disclaimer */}
      <Disclaimer />
    </section>
  );
}

export default UnitConversion;
