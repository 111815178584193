import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

/**
 * Component "FilterInfo"
 * @param {Object} props.filterInfoContent ein Objekt mit Titel und Infotext des angeklickten Filters
 */
function Filterinfo(props) {
  return (
    <Container>
      <Row noGutters={true}>
        <Col>
          <h1>{props.filterInfoContent.title}</h1>
          <p>{props.filterInfoContent.text}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Filterinfo;
