import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// import Content
import { unitConversionContentRauheit as content } from '../../assets/content';

/**
 * Component Rauheit Umrechnen
 */
function Rauheit() {
  const { t } = useTranslation([]);

  // state für den index das Eigabefeldes
  const [inputRauheit, setInputRauheit] = useState(0);
  // state für das Ausgabefeld
  const [outputRauheit, setOutputRauheit] = useState('');
  // state für input select
  const [rauheit1, setRauheit1] = useState('rk');
  // state für output select
  const [rauheit2, setRauheit2] = useState('ram');

  useEffect(() => {
    // Fragt ab welches output select target aktiv ist
    if (rauheit2 === 'rk') {
      setOutputRauheit(content.rk[inputRauheit]);
    } else if (rauheit2 === 'ram') {
      setOutputRauheit(content.ra[inputRauheit].m);
    } else if (rauheit2 === 'rain') {
      setOutputRauheit(content.ra[inputRauheit].in);
    } else if (rauheit2 === 'rzm') {
      setOutputRauheit(content.rz[inputRauheit].m);
    } else if (rauheit2 === 'rzin') {
      setOutputRauheit(content.rz[inputRauheit].in);
    }
  }, [inputRauheit, rauheit1, rauheit2]); // aktualisiert sich wenn sich diese states ändern.

  // Wenn der input select aktualisiert wird, ändert sich der input Feld index auf 0
  useEffect(() => {
    setInputRauheit(0);
    return () => {
      setInputRauheit(0);
    };
  }, [rauheit1]);

  /**
   * Funktion um das target ins clipboard zu kopieren
   * @param {event} e  Feld das angeklickt wurde
   */
  const _copyCodeToClipboard = (e) => {
    alert('Copied ' + e.value + ' to clipboard');
    e.select();
    document.execCommand('copy');
  };

  /**
   * Funktion die abfragt welches input select target aktiv
   */
  const Input = () => {
    if (rauheit1 === 'rk') {
      return content.rk.map((option, index) => (
        <option key={'rk' + index.toString()} value={index}>
          {option}
        </option>
      ));
    } else if (rauheit1 === 'ram') {
      return content.ra.map((option, index) => (
        <option key={'ram' + index.toString()} value={index}>
          {option.m}
        </option>
      ));
    } else if (rauheit1 === 'rain') {
      return content.ra.map((option, index) => (
        <option key={'rain' + index.toString()} value={index}>
          {option.in}
        </option>
      ));
    } else if (rauheit1 === 'rzm') {
      return content.rz.map((option, index) => (
        <option key={'rzm' + index.toString()} value={index}>
          {option.m}
        </option>
      ));
    } else if (rauheit1 === 'rzin') {
      return content.rz.map((option, index) => (
        <option key={'rzin' + index.toString()} value={index}>
          {option.in}
        </option>
      ));
    }
    return null;
  };

  return (
    <Container className='rauheit conversionElement'>
      <Row noGutters={true}>
        <Col>
          <h1>{t('UNITCONVERSION.ROUGHNESS.TITLE')}</h1>
        </Col>
      </Row>
      {/* Rauheit umrechner */}
      <Row noGutters={true}>
        <Col>
          <Form>
            {/* Input Felder */}
            <fieldset>
              <legend>{t('UNITCONVERSION.INPUT')}</legend>
              <Form.Row>
                <Col sm={6} md={4} lg={4} xl={2}>
                  <Form.Group controlId='form3field1'>
                    <Form.Control
                      as='select'
                      value={inputRauheit}
                      onChange={(e) => setInputRauheit(e.target.value)}
                    >
                      <Input />
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col sm={6} md={5} lg={4} xl={3}>
                  <Form.Group controlId='form3field2'>
                    <Form.Control
                      as='select'
                      value={rauheit1}
                      onChange={(e) => setRauheit1(e.target.value)}
                    >
                      <option value='rk'>
                        {t('UNITCONVERSION.ROUGHNESS.OPTION-RK')} (N)
                      </option>
                      <option value='ram'>Ra (μm)</option>
                      <option value='rain'>Ra (μin)</option>
                      <option value='rzm'>Rz (μm)</option>
                      <option value='rzin'>Rz (μin)</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </fieldset>
            {/* Output Felder */}
            <fieldset>
              <legend>{t('UNITCONVERSION.OUTPUT')}</legend>
              <Form.Row>
                <Col sm={6} md={4} lg={4} xl={2}>
                  <Form.Group controlId='form3field3'>
                    <Form.Control
                      type='text'
                      value={outputRauheit}
                      readOnly
                      onClick={(e) => _copyCodeToClipboard(e.target)}
                      className='noselect output'
                    />
                  </Form.Group>
                </Col>
                <Col sm={6} md={5} lg={4} xl={3}>
                  <Form.Group controlId='form3field4'>
                    <Form.Control
                      as='select'
                      value={rauheit2}
                      onChange={(e) => setRauheit2(e.target.value)}
                    >
                      <option value='rk'>
                        {t('UNITCONVERSION.ROUGHNESS.OPTION-RK')} (N)
                      </option>
                      <option value='ram'>Ra (μm)</option>
                      <option value='rain'>Ra (μin)</option>
                      <option value='rzm'>Rz (μm)</option>
                      <option value='rzin'>Rz (μin)</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </fieldset>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Rauheit;
